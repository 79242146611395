import { useEffect, useState } from "react"

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

export const useResizeHandler = () => {
  const [dimensions, setDimensions] = useState({})

  useEffect(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    })

    const debouncedHandleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 150)

    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [])

  return { dimensions }
}
