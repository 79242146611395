import React from "react"
import { useColumns } from "./hooks"
import { useResizeHandler } from "../../hooks"
import "./grid.less"

const Grid = ({ items, children }) => {
  const {
    dimensions: { width },
  } = useResizeHandler()

  const columns = useColumns({ items, width })

  if (!width) {
    return null
  }

  return (
    <section className="grid">
      {columns.map((column, index) => {
        return (
          <div
            key={`grid__column_${index}`}
            className="grid__column"
            style={{
              flex: `0 0 ${100 / columns.length}%`,
            }}
          >
            {column.map(item => {
              return (
                <div key={item.id} className="grid__item">
                  {children(item)}
                </div>
              )
            })}
          </div>
        )
      })}
    </section>
  )
}

export default Grid
